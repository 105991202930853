import {
    Center,
    Heading,
    useDisclose, Flex, Text, View, Spacer, ScrollView,
} from "native-base";
import * as React from "react";
import Layout from "../layouts/Layout";
import {Link, useIsFocused} from "@react-navigation/native";
import {Link  as Link2 }from "native-base";
import {useTranslation} from "react-i18next";
import SearchBarAlgolia from "../components/SearchBarAlgolia";
import {ImageBackground, Platform, Modal} from "react-native";
import Constants from "expo-constants";
import {useEffect} from "react";
import AccountService from "../services/AccountService";
import Config from "../config.json";
import {useSelector} from "react-redux";
import { AlertDialog, Button } from "native-base";
import WebView from "react-native-webview";

const ePostersPictureUrl = require('../../assets/explore_e_posters.jpg');
const speakersPictureUrl = require('../../assets/explore_speakers.jpg');
const exhibitorsPictureUrl = require('../../assets/explore_exhibitors.jpg');
const photoboothPictureUrl = require('../../assets/explore_photobooth.jpg');
const scientificPictureUrl = require('../../assets/explore_scientific.jpg');
const myBadgePictureUrl = require('../../assets/explore_my_badge.jpg');
const GeneralInfoPictureUrl = require('../../assets/explore_general_informations.jpg');
const TrainingLabsPictureUrl = require('../../assets/explore_training_labs.jpg');
const KioskPictureUrl = require('../../assets/explore_kiosk.jpg');

export default function ExploreScreen({navigation}) {
    const {t, i18n} = useTranslation();
    const isFocused = useIsFocused();
    const {congress} = useSelector(state => state.global);
    const {user} = useSelector(state => state.auth);
    const [isOpen, setIsOpen] = React.useState(true);
    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef(null);
    const [visible, setVisible] = React.useState(false);
    const openLinkInWebview = () => setVisible(true)

    // Ping
    useEffect(() => {
        let interval;
        if(isFocused) {
            interval = AccountService.startPing(navigation, {page: 'explore' });
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    const renderHeader = () => {
        if(Platform.OS === 'web') {
            return <Heading size={'2xl'} flex={3}
                            position={'absolute'} top={3} left={'50%'} style={{transform:'translateX(-50%)'}}
            >{t('Explore')}</Heading>
        }
        else {
            return <Heading size={'xl'} flex={3}>{t('Explore')}</Heading>;
        }
    }

    return (
        <Layout activePage={navigation.isFocused ? t('Menu_more') : ''}>
            {renderHeader()}
            <ScrollView mt={{lg:3}}>
            <Flex height={'100%'} width={'100%'} maxWidth={900} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <Spacer flex={1}/>
                <View flex={22} pt={{base:"50px",lg:'75px'}}>
                    <SearchBarAlgolia/>
                    <Flex
                        flexDirection="row"
                        justify={'space-between'}
                        mt={Platform.OS === "web" ? 2 : 4}
                        wrap={'wrap'}
                        w={'100%'}
                    >
                        {congress.can_evaluate && user.may_evaluate ?
                            <View
                                bg={"primary.600"}
                                w={'100%'} h={{base: 100, md: 200}}
                                my={{base: 2, md: 2}}
                                borderRadius={'5px'}
                            >
                                {Platform.OS === 'web'?
                                    <Link2
                                        href={`https://www.imcas.com/login-fast/${user.personal_token}?go=https://www.imcas.com/en/attend/${congress.slug}/evaluation` }
                                        isExternal
                                        onPress={onClose}
                                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                    >
                                        <ImageBackground
                                            source={GeneralInfoPictureUrl}
                                            imageStyle={{borderRadius: 5}}
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        >
                                            <View p={4}>
                                                <Text fontSize={{base: 18, md: 32}} bold color={'white'}>Evaluation</Text>
                                            </View>
                                        </ImageBackground>
                                    </Link2>:
                                    <>
                                        <Link2 onPress={openLinkInWebview} style={{width: '100%', height: '100%'}}>
                                            <ImageBackground
                                                source={GeneralInfoPictureUrl}
                                                imageStyle={{borderRadius: 5}}
                                                style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                            >
                                                <View p={4}>
                                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('Evaluation')}</Text>
                                                </View>
                                            </ImageBackground>
                                        </Link2>
                                        <Modal
                                            visible={visible}
                                            presentationStyle={'pageSheet'}
                                            animationType={'slide'}
                                            onRequestClose={() => setVisible(false)}
                                        >
                                            <WebView source={{uri: `https://www.imcas.com/login-fast/${user.personal_token}?go=https://www.imcas.com/en/attend/${congress.slug}/evaluation` }} />
                                        </Modal>
                                    </>
                                }
                            </View>
                            :<></>}
                        <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'Speakers'}}
                            />
                            <ImageBackground
                                source={speakersPictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View p={4}>
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('All_speakers')}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        {Config.hasKiosk ? (  <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'Kiosk'}}
                            />
                            <ImageBackground
                                source={KioskPictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View p={4}>
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('kiosk')}</Text>
                                </View>
                            </ImageBackground>
                        </View> ) :(<></>)}
                        <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'CompanyList'}}
                            />
                            <ImageBackground
                                source={exhibitorsPictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View
                                    p={4}
                                >
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('All_exhibitors')}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'Eposters'}}
                            />
                            <ImageBackground
                                source={ePostersPictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View
                                    p={4}
                                >
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}> E-posters</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'PhotoBooth'}}
                            />
                            <ImageBackground
                                source={photoboothPictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View
                                    p={4}
                                >
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('Photo_booth')}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'PartnerList'}}
                            />
                            <ImageBackground
                                source={scientificPictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View p={4}>
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('Scientific_partner')}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'MyBadge'}}
                            />
                            <ImageBackground
                                source={myBadgePictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View p={4}>
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('My_badge')}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'GeneralInfos'}}
                            />
                            <ImageBackground
                                source={GeneralInfoPictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View p={4}>
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('General_information')}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        <View
                            bg={"primary.600"}
                            w={'49%'} h={{base: 100, md: 200}}
                            my={{base: 1, md: 2}}
                            borderRadius={'5px'}
                        >
                            <Link style={styles.link}
                                  to={{screen: 'TrainingLabsAndHospitalitySuites'}}
                            />
                            <ImageBackground
                                source={TrainingLabsPictureUrl}
                                imageStyle={{borderRadius: 5}}
                                style={{width: '100%', height: '100%'}}
                            >
                                <View p={4} pb={0}>
                                    <Text fontSize={{base: 18, md: 32}} bold color={'white'}>{t('Training_labs_hospitality_suites')}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                    </Flex>
                    <Center>
                        <Text fontSize={"xs"} m={2}>V. {Config.appVersion} - {Config.build} ({Config.type}{Config.environment !== 'production' ? '- ' + Config.environment : ''})
                        </Text>
                    </Center>
                </View>
            </Flex>
            </ScrollView>
        </Layout>
    );
}

const styles = {
    link: {
        color: 'white',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 10,
        elevation: 10
    }
}
