import React, {useEffect, useState} from 'react';
import {
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    Image,
    ScrollView,
    Spinner,
    Text,
    useTheme,
    View
} from "native-base";
import Layout from "../layouts/Layout";
import CompanyCard from "../components/CompanyCard";
import HttpService from "../services/HttpService";
import {useTranslation} from "react-i18next";
import CompanySession from "../components/CompanySession";
import CompanyFiles from "../components/CompanyFiles";
import News from "../components/News";
import AccountService from "../services/AccountService";
import {useSelector} from "react-redux";
import {Link, useIsFocused} from "@react-navigation/native";
import * as Device from "expo-device";
import FirebaseAnalytics from "../services/Analytics/Analytics";
import {Platform} from "react-native";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {format, parseISO} from "date-fns";

const CompanyProfileScreen = (props) => {
    const {t, i18n} = useTranslation();
    const {list} = useSelector(state => state.auth);
    const {isLoggedIn} = useSelector(state => state.auth);
    const {id} = props.route.params;
    const [company, setCompany] = useState({});
    const [companySessions, setCompanySessions] = useState([]);
    const [news, setNews] = useState([]);
    const [showSessions, setShowSessions] = useState(false);
    const [showFiles, setShowFiles] = useState(false);
    const [files, setFiles] = useState([]);
    const [tabIndex, setTabIndex] = useState(1);
    const [deviceType, setDeviceType] = useState(1);
    const {colors} = useTheme();
    const [trainingLabsAndHospitalitySuites, setTrainingLabsAndHospitalitySuites] = useState([]);
    const isFocused = useIsFocused();
    const [showTLHS, setShowTLHS] = useState(false);
    // Ping
    useEffect(() => {
        getDeviceType().then(type => setDeviceType(type));
    }, []);

    useEffect(() => {
        let interval;
        if(isFocused) {
            getCompany().then((response) => {
                if(response?.data?.data) {
                    FirebaseAnalytics.sendAnalyticsEventAsync('screen_view', {screen_name: 'company_profile', screen_id: response?.data?.data.id});
                }
            })
            getCompanySessions();
            getLiveNews();
            interval = AccountService.startPing(props.navigation, {page: 'company-profile', pageId: id});
        }

        return () => clearInterval(interval);
        }, [isFocused]);

    useEffect(() => {
        initialTabActive()
    }, [companySessions, files, trainingLabsAndHospitalitySuites]);

    useEffect(() => {
        if(Object.keys(company).length !== 0) {
            getTrainingLabsAndHospitalitySuites();
        }
    }, [company]);

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    const getCompany = () => {
        return HttpService
            .getData(`exhibitor/get/one/${id}`)
            .then(response => {
                setCompany(response.data.data);
                HttpService
                    .getData(`exhibitor/hit/${id}/profile`)
                    .then(response => {
                        console.log('Hit: profile')
                    })
                    .catch(error => {
                        console.log('Error: ' + error)
                    })
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    const initialTabActive = () => {
        if (companySessions?.length > 0) {
            setTabIndex(1);
            setShowSessions(true);
        } else if (files?.length > 0) {
            setTabIndex(2);
            setShowFiles(true);
        } else if(trainingLabsAndHospitalitySuites.length > 0) {
            setTabIndex(3);
            setShowTLHS(true);
        }
    }

    const getCompanySessions = () => {
        HttpService
            .getData(`session/get/onReplayForExhibitor/${id}`)
            .then(response => {
                setCompanySessions(response.data.data);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    const getLiveNews = () => {
        HttpService
            .getData(`news/get/byExhibitor/${id}`)
            .then(response => {
                setNews(response.data.data);
            })
            .catch(error => {
                console.log('Error: ' + error)
            })
    }

    const getTrainingLabsAndHospitalitySuites = () => {
        HttpService.getData(`session/get/workshop/${company.user_id}`)
            .then(response => {
                setTrainingLabsAndHospitalitySuites(response.data.data);
            })
            .catch(error => {
                console.log('Error: ', error);
            })
    }

    const fileUrlArray = () => {
        let arr = [];
        if (company.live_downloadable_document1_url !== null && company.live_downloadable_document1_url !== undefined) {
            arr = [...arr, {
                url: company.live_downloadable_document1_url,
                title: company.live_downloadable_document1_title ?? company.sponsor_company_name + ' document'
            }];
        }
        if (company.live_downloadable_document2_url !== null && company.live_downloadable_document2_url !== undefined) {
            arr = [...arr, {
                url: company.live_downloadable_document2_url,
                title: company.live_downloadable_document2_title ?? company.sponsor_company_name + ' document'
            }];
        }
        if (company.live_downloadable_document3_url !== null && company.live_downloadable_document3_url !== undefined) {
            arr = [...arr, {
                url: company.live_downloadable_document3_url,
                title: company.live_downloadable_document3_title ?? company.sponsor_company_name + ' document'
            }];
        }
        if (company.live_downloadable_document4_url !== null && company.live_downloadable_document4_url !== undefined) {
            arr = [...arr, {
                url: company.live_downloadable_document4_url,
                title: company.live_downloadable_document4_title ?? company.sponsor_company_name + ' document'
            }];
        }
        if (company.live_downloadable_document5_url !== null && company.live_downloadable_document5_url !== undefined) {
            arr = [...arr, {
                url: company.live_downloadable_document5_url,
                title: company.live_downloadable_document5_title ?? company.sponsor_company_name + ' document'
            }];
        }
        setFiles(arr);
    }

    useEffect(() => {
        fileUrlArray();
    }, [company]);

    function goToSessionsTab() {
        setShowFiles(false);
        setShowSessions(true);
        setShowTLHS(false);
        setTabIndex(1);
    }

    function goToDocumentsTab() {
        setShowFiles(true);
        setShowSessions(false);
        setShowTLHS(false);
        setTabIndex(2);
    }

    function goToTLHS() {
        setShowFiles(false);
        setShowSessions(false);
        setShowTLHS(true);
        setTabIndex(3);
    }

    const renderSpinner = () => (
        <Center flex={1}>
            <HStack space={2} alignItems="center">
                <Spinner color="#5766D6"  accessibilityLabel="Loading posts"/>
                <Heading color="#5766D6" fontSize="md">Loading ...</Heading>
            </HStack>
        </Center>)

    function getTitle(type, title) {
        if (type === 299) {
            const trainingLabsToRemove = /\-*\s*training\s*lab/gi;
            return title.replace(trainingLabsToRemove, '');
        } else if (type === 300 ) {
            const hospitalitySuitesToRemove = /\-*\s*hospitality\s*suite/gi;
            return title.replace(hospitalitySuitesToRemove, '');
        } else {
            return title;
        }
    }
    const renderTLHS = () => {
        let items = [];
        if(trainingLabsAndHospitalitySuites.length > 0) {
            trainingLabsAndHospitalitySuites.forEach(item => {
                items.push(
                    <View key={item.id} width={{base :"100%", md:"70%"}} mx={"auto"} mt={19}>
                        <View flexDirection={'column'} justifyContent={"center"} alignItems={'center'}  p={2} bg={colors['backgroundLight'][50]} borderTopRadius={"lg"}>
                            <Text my={2} fontSize={"md"} fontWeight="bold" color={colors['backgroundLight'][600]}>{getTitle(item.format.id, String(item.title).split(": ")[0])}</Text>
                            <Text fontSize={'sm'} alignSelf={'flex-end'} fontWeight="bold" mt={2} color={colors['backgroundLight'][600]}><MaterialCommunityIcons name="home-variant" size={20} color={colors['backgroundLight'][600]} />{String(item.title).split(": ")[1]}</Text>
                        </View>
                        <View p={2} border={`2px solid ${colors['backgroundLight'][50]}`} borderBottomRadius={'lg'}>
                            <Text fontSize={"sm"}>{item.subtitle}</Text>
                            <Text fontSize={"sm"} mt={6} italic>{item.objectives}</Text>
                        </View>
                    </View>
                )
            });
            return (
                <>
                    {items}
                </>
            );
        }
    }

    let isChecked = false;
    if (list && list.find(listItem => listItem.entity_id === id)) isChecked = true;

    return (
        <Layout withBackButton activePage={props.navigation.isFocused ? 'Exhibition' : ''}>
            {company ?
                (
                    <ScrollView
                        maxWidth={{md: 900}}
                        margin={{md: 'auto'}}
                        width={'100%'}
                        overflowX={'visible'}
                        overflowY={'visible'}
                    >
                        <CompanyCard company={company} isChecked={isChecked}/>
                        {news?.length > 0 && <News
                            title={t('Company_news')}
                            itemMobileWidth={280}
                            itemMobileHeight={'100px'}
                            itemDesktopWidth={420}
                            itemDesktopHeight={'100px'}
                            screen={`CompanyProfile/${id}`}
                            data={news}
                        />}
                        {companySessions?.length > 0 || files?.length > 0 ?
                            (
                                <>
                                    <View
                                        pX={5}
                                    >
                                        <Flex my={5} direction='row' justify={'space-around'}>
                                            {companySessions?.length > 0 &&
                                                <Button
                                                    mx={1}
                                                    flex={1}
                                                    borderBottomWidth={tabIndex === 1 ? 1 : 0}
                                                    borderRadius={0}
                                                    borderBottomColor={tabIndex === 1 ? colors['action'][50] : ''}
                                                    onPress={goToSessionsTab}>
                                                    <Text textAlign='center' bold>{t('Live_upcoming')}</Text>
                                                </Button>}
                                            {files?.length > 0 &&
                                                <Button
                                                    mx={1}
                                                    variant={'unstyled'}
                                                    flex={1}
                                                    borderBottomWidth={tabIndex === 2 ? 1 : 0}
                                                    borderRadius={0}
                                                    borderBottomColor={tabIndex === 2 ? colors['action'][50] : ''}
                                                    onPress={goToDocumentsTab}
                                                >
                                                    <Text textAlign='center' bold>{t('Company_files')}</Text>
                                                </Button>}
                                            {trainingLabsAndHospitalitySuites?.length > 0 &&
                                                <Button
                                                    mx={1}
                                                    variant={'unstyled'}
                                                    flex={1}
                                                    borderBottomWidth={tabIndex === 3 ? 1 : 0}
                                                    borderRadius={0}
                                                    borderBottomColor={tabIndex === 3 ? colors['action'][50] : "transparent"}
                                                    onPress={goToTLHS}
                                                >
                                                    <Text textAlign='center' bold>{t('Training_labs_hospitality_suites')}</Text>
                                                </Button>
                                            }
                                        </Flex>
                                    </View>
                                    {(showSessions && companySessions?.length > 0 && tabIndex === 1) &&
                                        (<View px={3}>
                                            <CompanySession
                                                id={id}
                                                companySessions={companySessions}
                                                deviceType={deviceType}
                                            />
                                        </View>)}
                                    {(showFiles && files?.length > 0 && tabIndex === 2) &&
                                        <View minHeight={200}>
                                            <CompanyFiles files={files}/>
                                        </View>}
                                    {(showTLHS && trainingLabsAndHospitalitySuites?.length > 0 && tabIndex === 3) &&
                                        <View minHeight={200}>
                                            {renderTLHS()}
                                        </View>}
                                </>) : <></>}
                        {isLoggedIn ?
                            <></>
                            :
                            <View bgColor={colors['action'][50]} borderRadius={10} mt={5}>
                              <Link to={{screen: 'Login'}}
                                      style={{paddingVertical: '10px', textAlign: 'center'}}>
                                    <Text color={'white'}>Log into your account to access all functionalities</Text>
                                </Link>
                            </View>
                        }
                    </ScrollView>) :
                // spinner if data are loading
                renderSpinner()}
        </Layout>
    );
};

export default CompanyProfileScreen;
